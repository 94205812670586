import { createGtm } from '@gtm-support/vue-gtm';

export const gtm = createGtm({
    id: import.meta.env.VITE_GOOGLE_TAG ?? 'GTM-XXXXXXX',
    defer: false,
    compatibility: false,
    nonce: '2726c7f26c',
    enabled: import.meta.env.VITE_GOOGLE_TAG ? true : false,
    debug: import.meta.env.NODE_ENV !== 'production',
});
